import request from "@/api/config.js";

export function createJob(data) {
  return request({
    url: `job/create`,
    method: "post",
    data,
  });
}
export function updateJob(id, data) {
  return request({
    url: `job/${id}`,
    method: "put",
    data,
  });
}
export function getAll() {
  return request({
    url: `job/`,
    method: "get",
  });
}
export function getAllJobsForSections(data) {
  return request({
    url: `job/get-all/for/sections`,
    method: "post",
    data,
  });
}

export function getAllBySection(sectionId) {
  return request({
    url: `job/by-section/${sectionId}`,
    method: "get",
  });
}

export function getAllSelfProjects() {
  return request({
    url: `job/get-all/self/projects`,
    method: "get",
  });
}

export function getPinnedTaskCount(jobId) {
  return request({
    url: `/job/get-pinned-task-count/${jobId}`,
    method: "get",
  });
}

export function getWorkersCount(jobId) {
  return request({
    url: `/job/get-workers-count/${jobId}`,
    method: "get",
  });
}

export function getAllDifficulties() {
  return request({
    url: `job/getAllDifficulties`,
    method: "get",
  });
}

export function removeJob(data) {
  return request({
    url: `job/remove`,
    method: "post",
    data,
  });
}

export function findAllJobByUser(ID) {
  return request({
    url: `job/user/${ID}`,
    method: "get",
  });
}

export function findUserJobsWithProjects(ID) {
  return request({
    url: `job/get-all/${ID}/jobs/projects`,
    method: "get",
  });
}

export function addFollowersJob(taskid, data) {
  return request({
    url: `job/add-followers/${taskid}`,
    method: "post",
    data,
  });
}
export function removeFollowersJob(taskid, data) {
  return request({
    url: `job/remove-followers/${taskid}`,
    method: "post",
    data,
  });
}
export function getLatestFollowersJob(taskid) {
  return request({
    url: `job/latest-followers/${taskid}`,
    method: "get",
  });
}

export function pinTask(taskid, data) {
  return request({
    url: `job/pin-task/${taskid}`,
    method: "post",
    data,
  });
}

export function sendJobRequest(data) {
  return request({
    url: `job-candidate-request/create`,
    method: "post",
    data,
  });
}

export function getUserPinnedTask(user_id) {
  return request({
    url: `job/get/pinned-task/${user_id}`,
    method: "get",
  });
}
